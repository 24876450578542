.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  padding: 5px 15px;
  background-color: #ccc;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 14px;
}
.file_button {
  padding: 15px 15px;
  background-color: #ccc;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #282c34;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
}
/* Mobile screen*/
@media only screen and (max-width: 368px) {
  .main{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /*align-items: flex-start;*/
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .bbdd, .template, .downloader{
  min-width: 40%;
  margin: 0px auto;
  font-size: 15px;
  border: 2px solid #282c34;
  }

  .Previewer {
    display: block;
    width: 90%;
    min-height: 200px;
    margin: 0 auto;
    box-shadow: 1px 1px 1px #ccc;
    background-color: rgb(219, 218, 218);
    border-radius: 5px;
    color: #282c34;
    padding: 20px;
    margin-top: 25px;
  }

}
/* tablet screen*/

@media only screen and (max-width: 768px) {

  .main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*align-items: flex-start;*/
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .bbdd, .template, .downloader{
  max-width: 40%;
  margin: 20px 40px;
  font-size: 15px;
  border: 2px solid #282c34;
  }

  .Previewer {
    display: block;
    width: 90%;
    min-height: 200px;
    margin: 0 auto;
    box-shadow: 1px 1px 1px #ccc;
    background-color: rgb(219, 218, 218);
    border-radius: 5px;
    color: #282c34;
    padding: 20px;
    margin-top: 25px;
  }
  
}
/*Desktop screen*/
@media only screen and (min-width: 769px){

  .main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*align-items: flex-start;*/
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .bbdd, .template, .downloader{
  max-width: 40%;
  margin: 20px 40px;
  font-size: 15px;
  border: 2px solid #282c34;
  }

  .Previewer {
    display: block;
    width: 80%;
    max-width: 1200px;
    min-height: 400px;
    margin: 0 auto;
    box-shadow: 1px 1px 1px #ccc;
    background-color: rgb(219, 218, 218);
    border-radius: 5px;
    color: #282c34;
    padding: 20px;
    margin-top: 25px;
  }

}
